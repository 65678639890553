import React from 'react';
import Layout from '../components/Layout';
const IndexPage = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <div className="wrapper">
        <header>
          <div className="inner">
            <h2>Commercial Moving and Corporate Solutions</h2>
            <p>
              Does your business need movers? We are happy to work with any
              sized business to get your shipment or shipments expedited.
            </p>
          </div>
        </header>
        <div className="inner">
          <section id="two" className="wrapper alt spotlight style2">
            <div>
              {' '}
              <h3 className="major">Corporate Moving Solutions</h3>
            </div>
            <p>
              Whether it's just a warehouse being moved to a newer warehouse
              cross town, or a new storefront opening in the big city, we are
              here to help. We can handle packing all of your fragiles,
              dissassembling any displays, and arranging your things where they
              need to go. For a corporate move, or commercial move often times
              clients require expedited shipping service so just let us know
              what delivery window you need and we will make it happen for you.
              If you will have a number of employees moving as well we will be
              happy to arrange getting all of them set up in their new homes so
              that your business is at peak productivity as soon as possible.
            </p>
          </section>
          <section className="wrapper spotlight style1">
            <div className="included-list-wrapper">
              <div>
                <h3>Recent commercial moves we have taken care of include</h3>{' '}
              </div>{' '}
              <div>
                <ul>
                  <li>
                    Moving a 6000 square foot commercial warehouse for a fleet
                    of food trucks
                  </li>
                  <li>
                    Relocating dozens of California Highway Patrol officers from
                    Northern California to Southern California
                  </li>
                  <li>
                    Relocating thousands of members of the armed forces every
                    year to military bases around the country
                  </li>
                  <li>
                    Moving patients and their belongings for several of the
                    largest insurance companies in the country
                  </li>
                  <li>
                    Moving cutting edge startups into larger offices as their
                    businesses expand
                  </li>
                  <li>
                    Moving 100 workers and a 3 floor office for a Forbes 500
                    company{' '}
                  </li>
                  <li>
                    Moving an up and coming boutique fashion desinger to her new
                    shop on Rodeo Drive
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  </Layout>
);
export default IndexPage;
